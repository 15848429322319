import Platform from "./Platform.mjs";

namespace Env {
  export let debug = false;
  export const resolveUrl = (url: string) =>
    url.replace(
      /<HOSTNAME>/g,
      Platform.isClient ? window.location.hostname : "localhost",
    );
}

export default Env;
